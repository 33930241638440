const fetchFlag = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/flagicon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchMenuItem = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/menu/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchLogoImg = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/logoImg/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchHeroSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/heroSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchSubTextHero = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/subTextHero/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchHeroImg = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/heroSecImg/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchHeroIcon = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/heroSecIcon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchProSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/proSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchProSecImg = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/proSecImg/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchCatSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/catSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchCatSecImg = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/catSecImg/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchCountData = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/countData/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchCatlog = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/catlog/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching flags:", error.message);
    return [];
  }
};
const fetchBlogSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/blogSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching blog section:", error.message);
    return [];
  }
};

const fetchShopSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/shopSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching shop section:", error.message);
    return [];
  }
};

const fetchShopSecIcon = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/shopSecIcon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching shop section icons:", error.message);
    return [];
  }
};

const fetchCompanySec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/companySec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching company section:", error.message);
    return [];
  }
};

const fetchCompanySecIcon = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/companySecIcon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching company section icons:", error.message);
    return [];
  }
};

const fetchAboutSec = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/aboutSec/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching about section:", error.message);
    return [];
  }
};

const fetchAboutSecIcon = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/aboutSecIcon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching about section icons:", error.message);
    return [];
  }
};

const fetchMorePage = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/morePage/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching more page:", error.message);
    return [];
  }
};

const fetchMainWebLink = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/mainWebLink/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching main web link:", error.message);
    return [];
  }
};

const fetchMainWebImg = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/mainWebImg/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching main web image:", error.message);
    return [];
  }
};

const fetchFooterDetails = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/footerDetails/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching footer details:", error.message);
    return [];
  }
};

const fetchContactLeftIcon = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/contactLeftIcon/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching contact left icon:", error.message);
    return [];
  }
};

const fetchSinglePostIcons = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/singlePostIcons/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching single post icons:", error.message);
    return [];
  }
};

const fetchSinglePostSettings = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/singlePostSettings/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching single post settings:", error.message);
    return [];
  }
};

const fetchErrorLoading = async () => {
  try {
    const url = `${process.env.NEXT_PUBLIC_POST_URL}/api/siteData/errorLoading/${process.env.NEXT_PUBLIC_SITE_ID}`;

    const res = await fetch(url, { next: { revalidate: 60 } });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch site data: ${res.status} ${res.statusText}`
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching error loading data:", error.message);
    return [];
  }
};

const fetchPostData = async (
  page = 1,
  limit = 8,
  searchTerm = "",
  category = null
) => {
  try {
    const queryParams = new URLSearchParams({
      page,
      limit,
      ...(searchTerm && { search: searchTerm }),
      ...(category !== null && { category_id: category }),
    });

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_POST_URL}/api/posts/site/${process.env.NEXT_PUBLIC_SITE_ID}?${queryParams}`,
      { next: { revalidate: 60 } }
    );

    if (!response.ok) throw new Error("Failed to fetch data");
    return response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    return { posts: [], totalPages: 0 };
  }
};
const fetchCategory = async () => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_POST_URL}/api/category/site/${process.env.NEXT_PUBLIC_SITE_ID}`,
      { next: { revalidate: 60 } }
    );
    if (!response.ok) throw new Error("Failed to fetch categories");
    return response.json();
  } catch (error) {
    console.error("Error fetching category:", error);
    return [];
  }
};
const singlePostFetch = async (slug) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_POST_URL}/api/posts/singlepost/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          slug,
          postId: process.env.NEXT_PUBLIC_SITE_ID,
        }),
      },
      { next: { revalidate: 60 } }
    );

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error fetching single post:", error);
    return [];
  }
};

const fetchMostPopular = async () => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_POST_URL}/api/posts/mostpopularpost/${process.env.NEXT_PUBLIC_SITE_ID}`,
      { next: { revalidate: 60 } }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
const fetchPopular = async () => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_POST_URL}/api/posts/popularpost/${process.env.NEXT_PUBLIC_SITE_ID}`,
      { next: { revalidate: 60 } }
    );
    const res = await response.json();
    return res.length > 0 ? res : [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export {
  fetchPopular,
  fetchMostPopular,
  fetchPostData,
  singlePostFetch,
  fetchCategory,
  fetchFlag,
  fetchMenuItem,
  fetchLogoImg,
  fetchHeroSec,
  fetchSubTextHero,
  fetchHeroImg,
  fetchHeroIcon,
  fetchProSec,
  fetchProSecImg,
  fetchCatSec,
  fetchCatSecImg,
  fetchCountData,
  fetchCatlog,
  fetchBlogSec,
  fetchShopSec,
  fetchShopSecIcon,
  fetchCompanySec,
  fetchCompanySecIcon,
  fetchAboutSec,
  fetchAboutSecIcon,
  fetchMorePage,
  fetchMainWebLink,
  fetchMainWebImg,
  fetchFooterDetails,
  fetchContactLeftIcon,
  fetchSinglePostIcons,
  fetchSinglePostSettings,
  fetchErrorLoading,
};
